var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.reminder.reminderImagePath,
            _vm.reminder.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.reminder.reminderImageIsDefault &&
          _vm.checkPrivilege(_vm.hasReminderDeleteImage())},on:{"changeValue":function($event){_vm.reminder.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBoxMultiple',{attrs:{"className":'col-md-12',"id":(_vm.id + "-placesTokensList"),"value":_vm.reminder.placesTokensList,"options":_vm.placesTokensListOptions,"title":_vm.$t('clients.select'),"imgName":'clients.svg',"returnArrayOfObjects":false},on:{"changeValue":function($event){_vm.reminder.placesTokensList = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.reminder.fullCode,"title":_vm.$t('Reminders.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.reminder.fullCode = $event}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-reminderDateTime"),"errors":_vm.errors_reminderDateTime,"type":"dateTime","value":_vm.reminder.reminderDateTime,"title":_vm.$t('actionsData.dateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.reminder.reminderDateTime = $event.dateTime;
            _vm.$v.reminder.reminderDateTime.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-reminderTitleAr"),"errors":_vm.errors_reminderTitleAr,"value":_vm.reminder.reminderTitleAr,"title":_vm.$t('Reminders.titleAr'),"imgName":'reminders.svg'},on:{"changeValue":function($event){_vm.reminder.reminderTitleAr = $event;
            _vm.$v.reminder.reminderTitleAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-reminderTitleEn"),"errors":_vm.errors_reminderTitleEn,"value":_vm.reminder.reminderTitleEn,"title":_vm.$t('Reminders.titleEn'),"imgName":'reminders.svg'},on:{"changeValue":function($event){_vm.reminder.reminderTitleEn = $event;
            _vm.$v.reminder.reminderTitleEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-reminderNotes"),"value":_vm.reminder.reminderNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.reminder.reminderNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }